import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import Nav from './Nav';
import Footer from './Footer';

const Container = tw.div`flex flex-col min-h-screen`;
const Main = tw.main`flex-grow`;

export default function Layout({ children }) {
  return (
    <Container>
      <Nav />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};
