import React from 'react';
import PropTypes from 'prop-types';
import { Link as BaseLink } from 'gatsby';
import tw, { styled } from 'twin.macro';

import Logo from './Logo';

const StyledNav = tw.nav`flex justify-between pt-8 pb-4 px-10 border-b border-gold`;

const LinkContainer = tw.div`flex space-x-4`;

const Link = styled(BaseLink)`
  &.active-link {
    ${tw`text-navy-light`}
  }
  ${tw`flex flex-col items-center`}
`;

const SVG = styled.svg`
  height: 8px;
  width: 8px;
  display: none;
  ${Link}.active-link & {
    display: block;
  }
`;

const Circle = styled.circle`
  ${tw`fill-current text-gold`}
`;

const NavLink = ({ to, children }) => (
  <Link to={to} activeClassName="active-link">
    {children}
    <SVG>
      <Circle cx="4" cy="4" r="4" />
    </SVG>
  </Link>
);

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default function Nav() {
  return (
    <StyledNav>
      <BaseLink to="/">
        <Logo />
      </BaseLink>
      <LinkContainer>
        <NavLink to="/research">Research</NavLink>
        <NavLink to="/platform">Platform</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </LinkContainer>
      <a href="/" tw="border border-gold py-1 px-6 rounded-sm">
        Sign in
      </a>
    </StyledNav>
  );
}
