import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

const fillColors = {
  white: tw`text-white`,
  black: tw`text-black`,
  gold: tw`text-gold`
};

const WinterCircleSVG = styled.svg`
  ${tw`fill-current`}
  ${({ fill }) => fill && fillColors[fill]}
`;

export default function WinterCircle({ className, fill }) {
  return (
    <WinterCircleSVG
      className={className}
      viewBox="0 0 194 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4332 3.7367L12.3232 13.6797L8.9582 4.1617H7.8702L4.4872 13.6797L1.3762 3.7367H0.000198364V3.8547L3.8242 15.6347H5.0982L8.4142 6.2337L11.7112 15.6347H13.0032L16.8282 3.8547V3.7367H15.4332Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.61 15.6348H23.969V3.7368H22.61V15.6348Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1688 3.7367V13.4257L32.2138 3.7367H30.9908V15.6347H32.3498V5.9287L40.3228 15.6347H41.5298V3.7367H40.1688Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3274 3.7367V5.0287H52.0364V15.6347H53.3954V5.0287H58.1214V3.7367H47.3274Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.9018 3.7367V15.6347H73.1318V14.3257H65.2618V10.3147H70.2768V9.0227H65.2618V5.0287H73.0468V3.7367H63.9018Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.6123 9.5665H85.4053C86.8673 9.5665 87.9723 8.6995 87.9723 7.3055C87.9723 5.8945 86.8673 5.0285 85.4053 5.0285H80.6123V9.5665ZM90.0113 15.5335V15.6345H88.5503L85.3033 10.8405H80.6123V15.6345H79.2523V3.7365H85.4573C87.6493 3.7365 89.3323 5.1805 89.3323 7.3055C89.3323 8.9885 88.2613 10.2295 86.7313 10.6705L90.0113 15.5335Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.698 9.6857C103.698 6.2517 106.111 3.5667 109.817 3.5667C112.316 3.5667 114.219 4.8577 115.07 6.5747L113.914 7.2377C113.267 5.8097 111.755 4.8577 109.851 4.8577C106.911 4.8577 105.057 6.9827 105.057 9.6857C105.057 12.3717 106.962 14.4967 109.902 14.4967C111.84 14.4967 113.352 13.5607 113.999 12.1327L115.155 12.7957C114.304 14.5127 112.401 15.8047 109.868 15.8047C106.163 15.8047 103.698 13.1197 103.698 9.6857Z"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="194"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 15.8048H193.594V-0.000198364H0V15.8048Z"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.293 15.6348H122.653V3.7368H121.293V15.6348Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.034 9.5665H135.827C137.289 9.5665 138.394 8.6995 138.394 7.3055C138.394 5.8945 137.289 5.0285 135.827 5.0285H131.034V9.5665ZM140.433 15.5335V15.6345H138.972L135.725 10.8405H131.034V15.6345H129.674V3.7365H135.879C138.071 3.7365 139.754 5.1805 139.754 7.3055C139.754 8.9885 138.683 10.2295 137.153 10.6705L140.433 15.5335Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145.348 9.6857C145.348 6.2517 147.762 3.5667 151.467 3.5667C153.966 3.5667 155.87 4.8577 156.72 6.5747L155.564 7.2377C154.918 5.8097 153.406 4.8577 151.501 4.8577C148.561 4.8577 146.708 6.9827 146.708 9.6857C146.708 12.3717 148.612 14.4967 151.553 14.4967C153.491 14.4967 155.003 13.5607 155.649 12.1327L156.805 12.7957C155.955 14.5127 154.051 15.8047 151.519 15.8047C147.813 15.8047 145.348 13.1197 145.348 9.6857Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M171.867 14.3263V15.6353H162.943V3.73631H164.303V14.3263H171.867Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187.048 14.3263V15.6353H177.818V3.73631H186.963V5.02831H179.178V9.02231H184.193V10.3143H179.178V14.3263H187.048Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.882 1.7804C191.927 1.7504 191.958 1.7094 191.975 1.6574C191.991 1.6064 192 1.5454 192 1.4724C192 1.3564 191.98 1.2684 191.939 1.2094C191.898 1.1494 191.83 1.1194 191.736 1.1194C191.698 1.1194 191.67 1.1244 191.652 1.1344C191.634 1.1444 191.621 1.1624 191.614 1.1874C191.608 1.2134 191.605 1.2514 191.605 1.3014V1.8254H191.695C191.776 1.8254 191.838 1.8104 191.882 1.7804ZM192.424 2.5844C192.38 2.6034 192.327 2.6134 192.266 2.6134C192.185 2.6134 192.119 2.5954 192.071 2.5614C192.024 2.5264 191.993 2.4674 191.979 2.3834L191.94 2.1714C191.928 2.1024 191.914 2.0474 191.901 2.0054C191.888 1.9634 191.868 1.9294 191.843 1.9054C191.818 1.8814 191.784 1.8684 191.742 1.8684H191.605V2.3574C191.605 2.4114 191.609 2.4504 191.616 2.4734C191.623 2.4984 191.638 2.5144 191.659 2.5234C191.681 2.5334 191.716 2.5384 191.764 2.5404V2.5834C191.688 2.5794 191.581 2.5774 191.443 2.5774C191.291 2.5774 191.179 2.5794 191.107 2.5834V2.5404C191.153 2.5384 191.189 2.5334 191.21 2.5234C191.232 2.5144 191.246 2.4984 191.254 2.4734C191.262 2.4504 191.266 2.4114 191.266 2.3574V1.3014C191.266 1.2494 191.262 1.2104 191.254 1.1854C191.246 1.1624 191.232 1.1454 191.21 1.1354C191.189 1.1274 191.153 1.1224 191.107 1.1194V1.0774C191.179 1.0814 191.286 1.0834 191.43 1.0834C191.486 1.0834 191.55 1.0834 191.619 1.0814C191.689 1.0794 191.736 1.0794 191.764 1.0794C191.959 1.0794 192.106 1.1114 192.205 1.1754C192.304 1.2394 192.354 1.3304 192.354 1.4494C192.354 1.5114 192.339 1.5714 192.309 1.6294C192.278 1.6854 192.229 1.7344 192.157 1.7764C192.086 1.8184 191.991 1.8454 191.874 1.8604H191.876C191.963 1.8684 192.033 1.8834 192.088 1.9034C192.143 1.9254 192.186 1.9554 192.217 1.9934C192.248 2.0324 192.271 2.0814 192.282 2.1404L192.331 2.3594C192.343 2.4174 192.357 2.4574 192.371 2.4794C192.386 2.5014 192.407 2.5124 192.435 2.5124C192.451 2.5114 192.466 2.5084 192.478 2.5004C192.49 2.4924 192.504 2.4804 192.52 2.4644L192.549 2.4894C192.51 2.5334 192.469 2.5644 192.424 2.5844Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.771 0.303802C190.933 0.303802 190.251 0.985802 190.251 1.8238C190.251 2.6608 190.933 3.3428 191.771 3.3428C192.608 3.3428 193.29 2.6608 193.29 1.8238C193.29 0.985802 192.608 0.303802 191.771 0.303802ZM191.771 3.6468C190.765 3.6468 189.947 2.8288 189.947 1.8238C189.947 0.817802 190.765 -0.000198364 191.771 -0.000198364C192.776 -0.000198364 193.594 0.817802 193.594 1.8238C193.594 2.8288 192.776 3.6468 191.771 3.6468Z"
        />
      </g>
    </WinterCircleSVG>
  );
}

WinterCircle.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string
};
