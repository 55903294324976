import React from 'react';
import { Link as BaseLink } from 'gatsby';
import tw, { styled } from 'twin.macro';

import BaseLogoIcon from './Logo/Icon';
import BaseWinterCircle from './Logo/WinterCircle';
import DisruptionLogo from './DisruptionLogo';

const DividerSVG = styled.svg`
  ${tw`fill-current stroke-current text-gold mt-11 mb-14`}
`;
const WinterCircleContainer = tw.div`mt-5 mb-12`;
const Link = tw(BaseLink)`px-4 underline text-xs font-extrabold`;
const LinkContanier = tw.div`flex flex-col items-center py-0.5 text-gold space-y-1 mb-4 sm:items-stretch sm:flex-row sm:divide-x sm:divide-white`;
const LogoIcon = tw(BaseLogoIcon)`w-14 h-12`;
const WinterCircle = tw(BaseWinterCircle)`w-72 h-4 sm:w-80 sm:h-7`;
const StyledFooter = tw.footer`flex flex-col items-center bg-navy text-white pt-14 pb-16 px-4`;
const FeaturedText = tw.p`text-gold text-sm font-bold py-4`;
const CopywriteText = tw.p`text-sm font-bold`;

export default function Footer() {
  return (
    <StyledFooter>
      <FeaturedText>As featured in</FeaturedText>
      <DisruptionLogo />
      <DividerSVG
        width="131"
        height="5"
        viewBox="0 0 131 5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="5.5" y1="2.5" x2="125.5" y2="2.5" strokeLinecap="round" />
        <circle cx="2.5" cy="2.5" r="2.5" />
        <circle cx="128.5" cy="2.5" r="2.5" />
      </DividerSVG>
      <LogoIcon width={56} height={50} />
      <WinterCircleContainer>
        <WinterCircle width={334} height={26} />
      </WinterCircleContainer>
      <LinkContanier>
        <Link to="#">GDPR</Link>
        <Link to="#">Terms & Conditions</Link>
        <Link to="#">Privacy Policy</Link>
        <Link to="#">Cookies</Link>
      </LinkContanier>
      <CopywriteText>Copyright 2019. All rights reserved.</CopywriteText>
    </StyledFooter>
  );
}
