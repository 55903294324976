import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

const IconSVG = tw.svg`fill-current text-gold`;

export default function Icon({ className }) {
  return (
    <IconSVG
      className={className}
      viewBox="0 0 26 23"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1546 7.73649H18.2364C18.1703 
      7.51566 18.0575 7.31604 17.9039 7.15257L20.3589 2.88225C20.4672 2.90795 
      20.5785 2.92498 20.6946 2.92498C20.811 2.92498 20.9223 2.90795 
      21.03 2.88225L23.4852 7.15347C23.3329 7.31663 23.2204 7.51566 
      23.1546 7.73649ZM21.03 13.4263C20.9223 13.4006 20.811 13.3832 
      20.6946 13.3832C20.5785 13.3832 20.4672 13.4006 20.3589 
      13.4263L19.131 11.2911L17.9039 9.15595C18.0575 8.99279 
      18.1703 8.79287 18.2364 8.57203H23.1546C23.2204 8.79287 
      23.3329 8.99189 23.4852 9.15535L21.03 13.4263ZM19.3057 
      14.428H14.3889C14.3232 14.2071 14.2101 14.0072 14.0565 
      13.8437L15.2841 11.7086L16.5117 9.57402C16.6198 9.60002 
      16.7314 9.61705 16.8474 9.61705C16.9638 9.61705 17.0748 9.60002 
      17.1831 9.57402L18.4114 11.7086L19.6384 13.8437C19.4845 14.0072 
      19.3714 14.2071 19.3057 14.428ZM17.1831 20.1184C17.0748 20.0924 
      16.9638 20.0753 16.8474 20.0753C16.7314 20.0753 16.6198 20.0924 
      16.5117 20.1184L14.0565 15.8474C14.2101 15.6843 14.3232 15.484 
      14.3889 15.2635H19.3057C19.3714 15.484 19.4845 15.6843 19.6384 
      15.8474L17.1831 20.1184ZM13.0003 13.3832C12.8836 13.3832 12.7726 
      13.4006 12.6646 13.4263L11.4364 11.2911L10.2094 9.15565C10.3632 
      8.99249 10.4751 8.79287 10.5409 8.57203H15.4588C15.5243 8.79287 
      15.6374 8.99279 15.7906 9.15595L14.5636 11.2911L13.336 
      13.4263C13.2277 13.4006 13.1164 13.3832 13.0003 
      13.3832ZM11.6114 14.428H6.69315C6.62738 14.2065 6.51488 14.0072 
      6.36102 13.8437L7.58924 11.7086L8.81716 9.57402C8.92519 9.60002 
      9.03679 9.61705 9.15285 9.61705C9.26922 9.61705 9.38052 9.60002 
      9.48855 9.57402L10.7165 11.7086L11.9435 13.8437C11.7899 14.0072 
      11.6774 14.2071 11.6114 14.428ZM9.48855 20.1189C9.38052 20.0924 
      9.26922 20.0753 9.15285 20.0753C9.03679 20.0753 8.92519 20.0924 
      8.81626 20.1184L6.36042 15.8477C6.51488 15.6846 6.62738 15.4846 
      6.69315 15.2635H11.6114C11.6774 15.4846 11.7899 15.6843 11.9435 
      15.8474L9.48855 20.1189ZM5.30392 13.3832C5.18786 13.3832 5.07655 
      13.4006 4.96822 13.4263L2.51358 9.15625C2.66654 8.99279 2.77963 
      8.79317 2.8457 8.57203H7.76423C7.83 8.79287 7.94309 8.99249 
      8.09636 9.15595L6.86754 11.2911L5.63933 13.4263C5.53159 13.4006 
      5.42029 13.3832 5.30392 13.3832ZM4.96822 2.88255C5.07655 2.90825 
      5.18786 2.92558 5.30392 2.92558C5.42029 2.92558 5.53159 2.90825 
      5.64022 2.88255L8.09636 7.15257C7.94309 7.31604 7.83 7.51566 
      7.76423 7.73649H2.8457C2.77963 7.51536 2.66773 7.31574 2.51358 
      7.15228L4.96822 2.88255ZM12.6646 2.88225C12.7726 2.90795 12.8836 
      2.92498 13.0003 2.92498C13.1164 2.92498 13.2277 2.90795 13.336 
      2.88225L15.7906 7.15257C15.6374 7.31604 15.5243 7.51566 15.4588 
      7.73649H10.5418C10.476 7.51566 10.3632 7.31604 10.2094 
      7.15287L12.6646 2.88225ZM24.5417 6.69237C24.4257 6.69237 24.3141 
      6.70911 24.2063 6.7351L21.7511 2.46448C21.9972 2.2024 22.1511 
      1.85157 22.1511 1.46249C22.1511 0.654744 21.4993 0.000298833 
      20.6946 0.000298833C19.8902 0.000298833 19.2384 0.654744 19.2384 
      1.46249C19.2384 1.85157 19.3917 2.2024 19.6384 2.46448L17.1831 
      6.7351C17.0748 6.70911 16.9638 6.69237 16.8474 6.69237C16.7314 
      6.69237 16.6198 6.70911 16.5117 6.7351L14.0565 2.46448C14.3029 
      2.2024 14.4562 1.85157 14.4562 1.46249C14.4562 0.654744 13.8047 
      0.000298833 13.0003 0.000298833C12.1959 0.000298833 11.5435 0.654744 
      11.5435 1.46249C11.5435 1.85157 11.6974 2.2024 11.9435 2.46448L9.48855 
      6.7351C9.38052 6.70911 9.26922 6.69237 9.15285 6.69237C9.03679 6.69237 
      8.92519 6.70911 8.81716 6.7351L6.36102 2.46478C6.60744 2.2027 
      6.7604 1.85217 6.7604 1.46249C6.7604 0.654445 6.11043 0 5.30392 
      0C4.4992 0 3.84774 0.654445 3.84774 1.46249C3.84774 1.85157 
      4.0013 2.2027 4.24772 2.46478L1.79248 6.73451C1.68415 6.70911 
      1.57255 6.69237 1.45678 6.69237C0.653839 6.69237 0 7.34652 0 
      8.15396C0 8.96171 0.653839 9.61705 1.45678 9.61705C1.57255 9.61705 
      1.68415 9.60002 1.79248 9.57402L4.24772 13.8437C4.0013 14.1055 
      3.84774 14.457 3.84774 14.8457C3.84774 15.6541 4.4992 16.3082 
      5.30392 16.3082C5.42029 16.3082 5.53159 16.2909 5.63933 
      16.2652L8.09636 20.5364C7.85024 20.7985 7.69667 21.1493 7.69667 
      21.5384C7.69667 22.345 8.34813 23 9.15285 23C9.95579 23 10.6093 
      22.345 10.6093 21.5384C10.6093 21.1493 10.4558 20.7985 10.2094 
      20.5364L12.6646 16.2652C12.7726 16.2909 12.8836 16.3082 13.0003 
      16.3082C13.1164 16.3082 13.2277 16.2909 13.336 16.2652L15.7906 
      20.5361C15.5448 20.7979 15.3913 21.1493 15.3913 21.5384C15.3913 
      22.345 16.043 23 16.8474 23C17.6519 23 18.3039 22.345 18.3039 
      21.5384C18.3039 21.1493 18.1504 20.7979 17.9039 20.5361L20.3589 
      16.2652C20.4672 16.2909 20.5785 16.3082 20.6946 16.3082C21.4993 
      16.3082 22.1511 15.6538 22.1511 14.8457C22.1511 14.457 21.9972 
      14.1058 21.7511 13.8437L24.2063 9.57402C24.3141 9.60002 24.4257 
      9.61705 24.5417 9.61705C25.3465 9.61705 26 8.96171 26 8.15396C26 
      7.34652 25.3465 6.69237 24.5417 6.69237Z"
      />
    </IconSVG>
  );
}

Icon.propTypes = {
  className: PropTypes.string
};
