import React from 'react';
import { GlobalStyles } from 'twin.macro';

import Layout from 'src/components/Layout';

const wrapPageElement = ({ element, props }) => (
  <>
    <GlobalStyles />
    <Layout {...props}>{element}</Layout>
  </>
);

export default wrapPageElement;
