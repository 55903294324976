import React from 'react';
import tw from 'twin.macro';

import BaseLogoIcon from './Icon';
import BaseWinterCircle from './WinterCircle';

const LogoIcon = tw(BaseLogoIcon)`w-6 h-6 inline-block mr-4`;
const WinterCircle = tw(BaseWinterCircle)`w-48 h-4 inline-block`;

export default function Logo() {
  return (
    <div>
      <LogoIcon />
      <WinterCircle />
    </div>
  );
}
